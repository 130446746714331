import React from "react";
import {
  Box,
  Typography,
  Card,
  Stack,
  Collapse,
  Alert,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  CircularProgress,
  InputLabel,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Controller } from "react-hook-form";
import Logo from "../icons/Logo";
import AgamonLogoText from "../icons/AgamonLogoText";
import TOTPSetup from "./TOTPSetup";

const Invite = ({
  inviteData,
  isValid,
  errorMessage,
  infoMessage,
  loadingStep,
  submitPersonalDetails,
  submitPassword,
  submitMFA,
  totpQr,
  totpSecret,
  activeStep,
  control,
}) => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "auto",
      }}
    >
      <Card variant="outlined" sx={{ p: 4, width: "500px" }}>
        <Stack spacing={2}>
          <Stack sx={{ pt: 5, alignItems: "center" }} spacing={3}>
            <Stack sx={{ alignItems: "center" }} spacing={1}>
              <Logo
                sx={{
                  fontSize: "2rem",
                  color: "primary.main",
                }}
              />
              <AgamonLogoText sx={{ width: "5rem", color: "primary.main" }} />
            </Stack>
          </Stack>
          <Box sx={{ mb: 2 }}>
            <Collapse in={!!errorMessage}>
              <Alert severity="error">{errorMessage}</Alert>
            </Collapse>
            <Collapse in={!!infoMessage}>
              <Alert severity="info">{infoMessage}</Alert>
            </Collapse>
          </Box>
          {isValid === true && (
            <>
              <Box sx={{ mb: 2 }}>
                <Typography>
                  {`Hi${inviteData.firstName ? ` ${inviteData.firstName}` : ""}, and welcome to Agamon! Please fill in the following details to complete your
              registration.`}
                </Typography>
              </Box>
              <Stepper activeStep={activeStep} orientation="vertical">
                {/* Step 1: Personal Information */}
                <Step>
                  <StepLabel>Personal Information</StepLabel>
                  <StepContent>
                    <form onSubmit={submitPersonalDetails}>
                      <Stack spacing={2}>
                        <Stack>
                          <InputLabel shrink>First Name</InputLabel>
                          <Controller
                            name="firstName"
                            control={control}
                            defaultValue={inviteData?.firstName || ""}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => (
                              <TextField {...field} placeholder="First name" fullWidth error={!!fieldState.error} />
                            )}
                          />
                        </Stack>
                        <Stack>
                          <InputLabel shrink>Last Name</InputLabel>
                          <Controller
                            name="lastName"
                            control={control}
                            defaultValue={inviteData?.lastName || ""}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => (
                              <TextField {...field} placeholder="Last name" fullWidth error={!!fieldState.error} />
                            )}
                          />
                        </Stack>
                        <Stack>
                          <InputLabel shrink>Email</InputLabel>
                          <Controller
                            name="email"
                            control={control}
                            defaultValue={inviteData?.email || ""}
                            render={({ field }) => <TextField {...field} placeholder="Email" fullWidth disabled />}
                          />
                        </Stack>
                        <LoadingButton loading={loadingStep} variant="contained" type="submit">
                          Continue
                        </LoadingButton>
                      </Stack>
                    </form>
                  </StepContent>
                </Step>

                {/* Step 2: Password Setup */}
                <Step>
                  <StepLabel>Password Setup</StepLabel>
                  <StepContent>
                    <form onSubmit={submitPassword}>
                      <Stack spacing={2}>
                        <Stack>
                          <InputLabel shrink>Password</InputLabel>
                          <Controller
                            name="password"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                type="password"
                                placeholder="Password"
                                fullWidth
                                error={!!fieldState.error}
                              />
                            )}
                          />
                        </Stack>
                        <Stack>
                          <InputLabel shrink>Confirm Password</InputLabel>
                          <Controller
                            name="confirmPassword"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                type="password"
                                placeholder="Confirm Password"
                                fullWidth
                                error={!!fieldState.error}
                              />
                            )}
                          />
                        </Stack>
                        <LoadingButton loading={loadingStep} variant="contained" type="submit">
                          Continue
                        </LoadingButton>
                      </Stack>
                    </form>
                  </StepContent>
                </Step>

                {/* Step 3: MFA Setup */}
                <Step>
                  <StepLabel>Multi-Factor Authentication (MFA)</StepLabel>
                  <StepContent>
                    <TOTPSetup totpQr={totpQr} totpSecret={totpSecret} handleSubmit={submitMFA} control={control} />
                  </StepContent>
                </Step>
              </Stepper>
              {activeStep === 3 && (
                <Box>
                  <Stack spacing={2} direction="row" alignItems={"center"} justifyContent={"center"}>
                    <CircularProgress />
                    <Typography>You're in! Redirecting...</Typography>
                  </Stack>
                </Box>
              )}
            </>
          )}
        </Stack>
      </Card>
    </Box>
  );
};

export default Invite;
