import { Controller } from "react-hook-form";
import { Stack, TextField, InputLabel, Box, Typography, IconButton, Tooltip } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React from "react";
import { InfoOutlined } from "@mui/icons-material";

const TOTPSetup = ({ totpQr, totpSecret, handleSubmit, control }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography>To access the platform, you must set up a TOTP for your account.</Typography>
          <Tooltip
            title="TOTP (Time-Based One-Time Password) adds an extra layer of security by generating a unique code on your authenticator app. It's required for secure access."
            arrow
          >
            <IconButton>
              <InfoOutlined />
            </IconButton>
          </Tooltip>
        </Stack>

        {totpQr && totpSecret && (
          <>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={totpQr.attributes.src}
                width={totpQr.attributes.width * 0.5}
                height={totpQr.attributes.height * 0.5}
                alt="TOTP QR Code"
              />
            </Box>

            <Stack sx={{ width: "100%" }}>
              <InputLabel shrink>TOTP secret key</InputLabel>
              <code style={{ padding: 0, margin: 0 }}>{totpSecret.attributes.text.text}</code>
            </Stack>

            <Stack sx={{ width: "100%" }}>
              <InputLabel shrink>6 digit code</InputLabel>
              <Controller
                name="mfaCode"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="6 digit code"
                    fullWidth
                    error={!!fieldState.error}
                    helperText={!!fieldState.error ? fieldState.error?.message : "This is your authenticator app code"}
                  />
                )}
              />
            </Stack>
          </>
        )}

        <LoadingButton variant="contained" type="submit">
          Complete TOTP Setup
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default TOTPSetup;
