export const handleSubmitWrapper = (handleSubmit, func, setLoadingStep, setErrorMessage, setInfoMessage) => {
  return async (event) => {
    event.preventDefault(); // Prevent default form submission
    if (setLoadingStep) {
      setLoadingStep(true);
    }
    if (setErrorMessage) {
      setErrorMessage(null);
    }
    if (setInfoMessage) {
      setInfoMessage(null);
    }
    try {
      await handleSubmit(async (data) => {
        await func(data);
      })();
    } catch (e) {
      if (setErrorMessage) {
        setErrorMessage(e.message);
      }
    } finally {
      if (setLoadingStep) {
        setLoadingStep(false);
      }
    }
  };
};

export const handleUIErrors = (ui, setErrorMessage) => {
  const { messages: generalMessages = [], nodes = [] } = ui;

  // Extract general messages
  const generalErrors = generalMessages.filter((msg) => msg.type === "error").map((msg) => msg.text);

  // Extract node-specific messages
  const nodeErrors = nodes
    .filter((node) => node.messages && node.messages.some((msg) => msg.type === "error"))
    .flatMap((node) => node.messages.filter((msg) => msg.type === "error").map((msg) => msg.text));

  // Combine general errors and node-specific errors
  const allErrors = [...generalErrors, ...nodeErrors];

  if (allErrors.length === 1) {
    setErrorMessage(allErrors[0]);
  } else if (allErrors.length > 1) {
    const formattedMessages = allErrors.map((msg) => `• ${msg}`).join("\n");
    setErrorMessage(`Errors:\n${formattedMessages}`);
  } else {
    setErrorMessage("An unknown error occurred.");
  }
};

function matchIsNumeric(text) {
  const isNumber = typeof text === "number";
  const isString = typeof text === "string";
  return (isNumber || (isString && text !== "" && text !== " ")) && !isNaN(Number(text));
}

export const validateChar = (value, index) => {
  return matchIsNumeric(value);
};
