import { Link, useNavigate } from "react-router-dom";
import React from "react";

export const ExpiredErrorMessage = () => {
  const navigate = useNavigate();

  return (
    <span>
      Session expired, please{" "}
      <Link
        to="#"
        onClick={(e) => {
          e.preventDefault();
          navigate(0); // Reloads the current page
        }}
      >
        refresh
      </Link>{" "}
      the page and try again.
    </span>
  );
};
