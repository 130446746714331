import "swiper/css";
import { Controller, useForm } from "react-hook-form";
import { Box, CircularProgress, FormHelperText, InputLabel, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import { Link, useSearchParams } from "react-router-dom";
import _ from "lodash";
import { handleSubmitWrapper, handleUIErrors, validateChar } from "../../utils/sso/forms";
import TOTPSetup from "./TOTPSetup";
import { MuiOtpInput } from "mui-one-time-password-input";

const DefaultLoginSlides = (props) => {
  const { swiper, setSwiper, setErrorMessage } = props;
  const [loadingStates, setLoadingStates] = useState([true, true, false]);
  const [searchParams] = useSearchParams();
  const [flowId, setFlowId] = useState();
  const [csrfToken, setCsrfToken] = useState();
  const [returnTo, setReturnTo] = useState();
  const [isTotpRequired, setIsTotpRequired] = useState(false);
  const [oidcNodes, setOidcNodes] = useState();
  const [totpQr, setTotpQr] = useState();
  const [totpSecret, setTotpSecret] = useState();

  const { control, handleSubmit } = useForm();
  const { control: controlPassword, handleSubmit: handleSubmitPassword } = useForm();
  const { control: controlTotpSetup, handleSubmit: handleSubmitTotpSetup } = useForm();

  const extractDataFromFlow = (flow, totp = false) => {
    setFlowId(flow.id);
    const csrf = _.find(flow.ui.nodes, { attributes: { name: "csrf_token" } });
    setCsrfToken(csrf?.attributes?.value || "");
    setOidcNodes(_.filter(flow.ui.nodes, { group: "oidc" }));
    setIsTotpRequired(totp);
  };

  const extractCsrfToken = (uiNodes) => {
    const csrfNode = _.find(uiNodes, (node) => _.get(node, "attributes.name") === "csrf_token");
    const token = _.get(csrfNode, "attributes.value", null);
    console.log("CSRF token extracted:", token ? "Yes" : "No", token);
    return token;
  };

  const initializeLoginFlow = async (totp = false) => {
    setCsrfToken(undefined);
    setFlowId(undefined);
    try {
      const response = await axios.get(AUTH_API + `/login/browser`, {
        params: { aal: "aal1", refresh: true },
        withCredentials: true,
        validateStatus: (status) => status === 200 || status === 400,
      });
      if (response.status === 200) {
        extractDataFromFlow(response.data, totp);
        await finalizeLogin(response.data);
      } else if (response.status === 400 && response.data.ui) {
        handleUIErrors(response.data.ui, setErrorMessage);
      }
    } catch (e) {
      console.error("Error initializing login flow:", e);
    }
  };

  const submitPassword = async (data) => {
    try {
      const response = await axios.post(
        AUTH_API + `/login/password`,
        { ...data, csrfToken },
        {
          params: { flow: flowId },
          withCredentials: true,
          validateStatus: (status) => [200, 400, 410, 422].includes(status),
        }
      );

      if (response.status === 200) {
        // Successful login
        await finalizeLogin(response.data);
      } else if (response.status === 400 && response.data.ui) {
        // Handle UI errors
        handleUIErrors(response.data.ui, setErrorMessage);
      } else if (response.status === 422 && response.data.error?.id === "browser_location_change_required") {
        // Handle location change required
        console.warn("Browser location change required:", response.data.error.reason);
        swiper.slideTo(2); // Navigate to slide 2
      } else if (response.status === 410 && response.data?.error?.id === "self_service_flow_expired") {
        setErrorMessage("Session expired, please refresh the page and try again.");
        console.warn("Session expired:", response.data.error.reason);
      }
    } catch (error) {
      console.error("Error submitting password:", error);
    }
  };

  const submitOidc = async (provider) => {
    try {
      const response = await axios.post(
        AUTH_API + `/login/oidc`,
        { provider, csrf_token: csrfToken },
        {
          params: { flow: flowId },
          withCredentials: true,
          validateStatus: (status) => [200, 400, 410].includes(status),
        }
      );
      if (response.status === 200) {
        await initializeLoginFlow();
      } else if (response.status === 400 && response.data.ui) {
        handleUIErrors(response.data.ui, setErrorMessage);
      } else if (response.status === 410 && response.data?.error?.id === "self_service_flow_expired") {
        setErrorMessage("Session expired, please refresh the page and try again.");
        console.warn("Session expired:", response.data.error.reason);
      }
    } catch (error) {
      console.error("Error submitting OIDC:", error);
    }
  };

  const submitTotp = async (data) => {
    try {
      const response = await axios.post(
        AUTH_API + `/login/totp`,
        { ...data, csrfToken },
        {
          params: { flow: flowId },
          withCredentials: true,
          validateStatus: (status) => [200, 400, 410].includes(status),
        }
      );
      if (response.status === 200) {
        swiper.slideTo(3);
        await finalizeLogin();
      } else if (response.status === 400 && response.data.ui) {
        handleUIErrors(response.data.ui, setErrorMessage);
      } else if (response.status === 410 && response.data?.error?.id === "self_service_flow_expired") {
        setErrorMessage("Session expired, please refresh the page and try again.");
        console.warn("Session expired:", response.data.error.reason);
      }
    } catch (error) {
      console.error("Error submitting TOTP:", error);
    }
  };

  const submitTotpSetup = async (data) => {
    try {
      console.log("Submitting MFA setup:", data);
      setErrorMessage(null);

      const response = await axios.post(
        AUTH_API + `/login/mfa`,
        {
          totpCode: data.mfaCode,
          csrfToken,
        },
        {
          params: {
            flow: flowId,
          },
          withCredentials: true,
          validateStatus: (status) => status === 200 || status === 400, // Do not throw for 400
        }
      );
      if (
        response.status === 200 &&
        response.data.identity &&
        response.data.state === "success" &&
        response.data.ui.messages.some((msg) => msg.type === "success" && msg.text === "Your changes have been saved!")
      ) {
        console.log("We set up MFA successfully.");
        swiper.slideTo(3);
      }
      if (response.status === 400 && response.data?.id) {
        handleUIErrors(response.data.ui, setErrorMessage);
      }
    } catch (error) {
      console.error("Unexpected error setting up MFA:", error);
      setErrorMessage("An unexpected error occurred during MFA setup.");
    }
  };

  const finalizeLogin = async (responseData) => {
    try {
      const continueWith = responseData?.continue_with;

      if (continueWith && continueWith.some((item) => item.action === "set_up_totp")) {
        console.log("TOTP setup required. Navigating to slide 1.");
        swiper.slideTo(1); // Navigate to slide 1 for TOTP setup
        return;
      }

      if (continueWith && continueWith.some((item) => item.action === "redirect_browser_to")) {
        swiper.slideTo(3);
        return;
      }

      console.log("No actionable items found in continue_with.");
    } catch (error) {
      console.error("Error finalizing login:", error);
    }
  };

  const createSettingsFlow = async () => {
    try {
      console.log("Initializing settings flow for MFA...");
      const response = await axios.get(AUTH_API + `/login/mfa`, { withCredentials: true });
      setFlowId(response.data.id);
      setCsrfToken(extractCsrfToken(response.data.ui.nodes));
      setTotpQr(
        _.find(response.data.ui.nodes, {
          attributes: { id: "totp_qr" },
        })
      );
      setTotpSecret(
        _.find(response.data.ui.nodes, {
          attributes: { text: { id: 1050006 } },
        })
      );
      console.log("Settings flow initialized with flowId:", response.data.id);
    } catch (error) {
      console.error("Error initializing settings flow:", error);
      throw new Error("Error initializing settings flow.");
    }
  };

  const initializeTotpFlow = async () => {
    try {
      console.log("initializeTotpFlow");
      const response = await axios.get(AUTH_API + `/login/browser`, {
        params: { aal: "aal2" }, // Use "aal2" for TOTP flow
        withCredentials: true,
        validateStatus: (status) => status === 200 || status === 400,
      });
      if (response.status === 200) {
        extractDataFromFlow(response.data, true); // Pass true for TOTP-specific logic
      } else if (response.status === 400 && response.data.ui) {
        handleUIErrors(response.data.ui, setErrorMessage);
      }
    } catch (e) {
      console.error("Error initializing TOTP flow:", e);
    }
  };

  useEffect(() => {
    const fetchLoginFlow = async () => {
      const flowIdParam = searchParams.get("flow");
      const returnToValue = searchParams.get("return_to");
      console.log(`Setting return to ${returnToValue}`);
      setReturnTo(returnToValue);
      if (flowIdParam) {
        try {
          const response = await axios.get(AUTH_API + `/login/flows`, {
            params: { id: flowIdParam },
            withCredentials: true,
            validateStatus: (status) => status === 200 || status === 400,
          });
          if (response.status === 200) {
            await finalizeLogin(response.data);
          } else if (response.status === 400 && response.data.ui) {
            handleUIErrors(response.data.ui, setErrorMessage);
          }
        } catch (error) {
          console.error("Error starting login flow:", error);
        }
      } else {
        await initializeLoginFlow();
      }
    };

    const fetchUser = async () => {
      try {
        const { data, status } = await axios.get(AUTH_API + "/whoami", {
          withCredentials: true,
          validateStatus: (status) => [200, 401, 403].includes(status), // Allow 403 to be treated as a valid response
        });

        if (status === 200 && data.active) {
          if (data.authenticator_assurance_level === "aal1") {
            finalizeLogin(data);
            return;
          } else if (data.authenticator_assurance_level === "aal2") {
            console.log("User logged in successfully. Redirecting...");
            swiper.slideTo(3);
            return;
          }
        } else if (status === 403 && data.error?.id === "session_aal2_required") {
          console.warn("AAL2 required. Starting flow from beginning.");
        }
        fetchLoginFlow();
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (swiper) {
      fetchUser();
    }
  }, [swiper]);

  useEffect(() => {
    if (swiper && flowId) {
      setLoadingStates((prev) => {
        const updatedStates = [...prev];
        updatedStates[0] = false;
        return updatedStates;
      });
    }
  }, [swiper, flowId]);

  useEffect(() => {
    if (swiper && totpQr) {
      setLoadingStates((prev) => {
        const updatedStates = [...prev];
        updatedStates[1] = false;
        return updatedStates;
      });
    }
  }, [swiper, totpQr]);

  useEffect(() => {
    if (swiper) {
      swiper.updateAutoHeight(300); // Smooth height update with 300ms transition
    }
  }, [loadingStates, swiper]);

  return (
    <Swiper
      simulateTouch={false}
      autoHeight={true}
      touchRatio={0}
      slidesPerView={1}
      enabled={true}
      speed={300} // That's actually the default
      onSwiper={(swiperInstance) => {
        console.log("Setting up swiper instance.");
        setSwiper(swiperInstance);
      }}
      onSlideChange={(swiperInstance) => {
        setErrorMessage(null);
        swiperInstance.updateAutoHeight(swiperInstance.speed);

        if (swiperInstance.activeIndex === 1) {
          console.log("Slide 1 active: initializing settings flow.");
          createSettingsFlow().catch((error) => {
            console.error("Error during createSettingsFlow:", error);
          });
        } else if (swiperInstance.activeIndex === 2) {
          console.log("Slide 2 active: initializing TOTP flow.");
          initializeTotpFlow().catch((error) => {
            console.error("Error during initializeTotpFlow:", error);
          });
        } else if (swiperInstance.activeIndex === 3) {
          console.log(`Redirecting to ${returnTo ?? "/coordinate/manage"}`);
          setTimeout(() => {
            window.location.href = returnTo ?? "/coordinate/manage";
          }, 3000);
        }
      }}
    >
      <SwiperSlide>
        {loadingStates[0] ? (
          <Stack alignItems={"center"}>
            <CircularProgress />
          </Stack>
        ) : (
          <form onSubmit={handleSubmitWrapper(handleSubmitPassword, submitPassword, null, setErrorMessage, null)}>
            <Stack sx={{ alignItems: "center" }} spacing={2}>
              <Stack sx={{ width: "100%" }}>
                <InputLabel shrink>Email</InputLabel>
                <Controller
                  name="email"
                  control={controlPassword}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <TextField {...field} placeholder="Enter email" fullWidth error={!!fieldState.error} />
                  )}
                />
              </Stack>
              <Stack sx={{ width: "100%" }}>
                <InputLabel shrink>Password</InputLabel>
                <Controller
                  name="password"
                  control={controlPassword}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      placeholder="Enter password"
                      type="password"
                      fullWidth
                      error={!!fieldState.error}
                    />
                  )}
                />
                <Box sx={{ mt: 1 }}>
                  <Link to={"/recovery"}>Forgot password?</Link>
                </Box>
              </Stack>

              <Stack sx={{ width: "50%" }} spacing={1}>
                <Controller
                  name="submit"
                  control={control}
                  render={({ formState: { isSubmitting } }) => (
                    <LoadingButton variant="contained" type="submit" size="large" loading={isSubmitting} fullWidth>
                      Sign in
                    </LoadingButton>
                  )}
                />
                {oidcNodes &&
                  oidcNodes.map((node) => (
                    <Controller
                      name="submit"
                      control={control}
                      render={({ formState: { isSubmitting } }) => (
                        <LoadingButton
                          type="submit"
                          loading={isSubmitting}
                          onClick={handleSubmitWrapper(handleSubmit, submitOidc, null, setErrorMessage, null)}
                          fullWidth
                        >
                          {node.meta.label.text}
                        </LoadingButton>
                      )}
                    />
                  ))}
              </Stack>
            </Stack>
          </form>
        )}
      </SwiperSlide>
      <SwiperSlide>
        {loadingStates[1] ? (
          <Stack alignItems={"center"}>
            <CircularProgress />
          </Stack>
        ) : (
          <Box>
            <TOTPSetup
              totpQr={totpQr}
              totpSecret={totpSecret}
              control={controlTotpSetup}
              flowId={flowId}
              csrfToken={csrfToken}
              handleSubmit={handleSubmitWrapper(handleSubmitTotpSetup, submitTotpSetup, null, setErrorMessage, null)}
              setErrorMessage={setErrorMessage}
            />
          </Box>
        )}
      </SwiperSlide>
      <SwiperSlide>
        {loadingStates[2] ? (
          <Stack alignItems={"center"}>
            <CircularProgress />
          </Stack>
        ) : (
          <form onSubmit={handleSubmitWrapper(handleSubmit, submitTotp, null, setErrorMessage, null)}>
            <Stack sx={{ alignItems: "center" }} spacing={2}>
              <Stack sx={{ width: "100%" }} spacing={1}>
                <InputLabel shrink>Two-Factor Authentication</InputLabel>
                <Controller
                  name="totp_code"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <>
                      <MuiOtpInput
                        {...field}
                        validateChar={validateChar}
                        length={6}
                        variant="outlined"
                        aria-label="OTP"
                        sx={{ marginBottom: 2 }}
                        disabled={!isTotpRequired}
                        fullWidth
                        error={!!fieldState.error}
                      />
                      <FormHelperText>Copy the code from your authentication app.</FormHelperText>
                    </>
                  )}
                />
              </Stack>
              <Stack sx={{ width: "50%" }} spacing={1}>
                <Controller
                  name="submit"
                  control={control}
                  render={({ formState: { isSubmitting } }) => (
                    <LoadingButton variant="contained" type="submit" size="large" loading={isSubmitting} fullWidth>
                      Submit
                    </LoadingButton>
                  )}
                />
              </Stack>
            </Stack>
          </form>
        )}
      </SwiperSlide>
      <SwiperSlide>
        <Stack alignItems={"center"}>
          <CircularProgress />
        </Stack>
      </SwiperSlide>
    </Swiper>
  );
};

export default DefaultLoginSlides;
