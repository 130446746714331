import React, { useEffect, useState } from "react";
import {
  default as WorkSansBoldWoff2,
  default as WorkSansRegularItWoff2,
} from "../styles/fonts/work-sans/work-sans-v17-latin-700.woff2";
import { createTheme, styled } from "@mui/material/styles";
import ConfigContext from "./ConfigContext";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TimeAgo from "javascript-time-ago";
import WorkSansBoldItWoff2 from "../styles/fonts/work-sans/work-sans-v17-latin-700italic.woff2";
import WorkSansRegularWoff2 from "../styles/fonts/work-sans/work-sans-v17-latin-regular.woff2";
import WorkSansSemiboldItWoff2 from "../styles/fonts/work-sans/work-sans-v17-latin-500italic.woff2";
import WorkSansSemiboldWoff2 from "../styles/fonts/work-sans/work-sans-v17-latin-500.woff2";

// Locale
import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);

const ConfigProvider = (props) => {
  const storedPreferenceDarkMode = localStorage.getItem("darkMode");
  const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches; // Looks for user's system preference
  const storedPreferenceDevTools = localStorage.getItem("devTools");
  const [openDevTools, setOpenDevTools] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(JSON.parse(storedPreferenceDarkMode) ?? prefersDarkMode);
  const [muiTheme, setMuiTheme] = useState(null);
  const [showDevOptionsPreference, setShowDevOptionsPreference] = useState(
    JSON.parse(storedPreferenceDevTools) ?? false
  );
  const showDevOptions = ["development", "staging"].includes(process.env.NODE_ENV) && showDevOptionsPreference;

  const getDesignTokens = (paletteMode) => {
    return {
      palette: {
        mode: paletteMode,
        ...(paletteMode === "light"
          ? {
              // palette values for light mode
              primary: {
                main: colors[paletteMode]["primary"],
              },
              secondary: {
                main: colors[paletteMode]["secondary"],
              },
              sidePrimary: {
                main: colors[paletteMode]["sidePrimary"],
              },
              background: {
                main: colors[paletteMode]["background"],
              },
              border: {
                main: colors[paletteMode]["border"],
              },
              content: {
                main: colors[paletteMode]["content"],
              },
              sidebar: {
                main: colors[paletteMode]["sidebar"],
              },
              sideSecondary: {
                main: colors[paletteMode]["sideSecondary"],
                secondary: "rgba(134,161,179,0.16)",
              },
            }
          : {
              primary: {
                main: colors[paletteMode]["primary"],
              },
              secondary: {
                main: colors[paletteMode]["secondary"],
              },
              // palette values for dark mode
            }),
        //color pallette
        yellow: {
          main: "rgba(183,98,40,0.12)",
        },
        green: {
          main: "rgba(0,172,162,0.12)",
        },
        purple: {
          main: "rgba(114,108,196,0.12)",
        },
        blue: {
          main: "rgba(44,150,240,0.12)",
        },
        red: {
          main: "rgba(211,74,111,0.12)",
        },
        gray: {
          main: "rgba(134,161,179,0.12)",
        },
      },
      shape: {
        borderRadius: 12,
      },
      typography: {
        fontFamily: "Work Sans",
        button: {
          textTransform: "none",
        },
        largeTitle: {
          fontSize: 32,
          fontWeight: "regular",
        },
        title1: {
          fontSize: 28,
          fontWeight: "regular",
        },
        title2: {
          fontSize: 24,
          fontWeight: "regular",
        },
        title3: {
          fontSize: 20,
          fontWeight: "bold",
        },
        headline: {
          fontSize: 16,
          fontWeight: "bold",
        },
        body: {
          fontSize: 16,
          fontWeight: "regular",
        },
        callout: {
          fontSize: 15,
          fontWeight: "regular",
        },
        subhead: {
          fontSize: 14,
          fontWeight: "regular",
        },
        footnote: {
          fontSize: 13,
          fontWeight: "regular",
        },
        caption1: {
          fontSize: 12,
          fontWeight: "regular",
        },
        caption2: {
          fontSize: 11,
          fontWeight: "regular",
        },
        body1: {
          fontSize: 16,
          fontWeight: "regular",
        },
        header: {
          fontSize: "1rem",
          fontWeight: "bold",
        },
        headerContent: {
          fontSize: "1rem",
          fontWeight: "bold",
        },
        bodyContent: {
          fontSize: "1rem",
          fontWeight: "regular",
        },
      },
      components: {
        MuiButtonBase: {
          defaultProps: {
            disableRipple: true,
          },
        },
        MuiBackdrop: {
          styleOverrides: {
            root: {
              backdropFilter: "blur(4px)",
            },
            invisible: {
              backdropFilter: "none",
            },
          },
        },
        MuiButton: {
          defaultProps: {
            disableRipple: true,
            disableElevation: true,
          },
          styleOverrides: {
            root: {
              // paddingBottom: 4,
              // paddingTop: 4,
              // paddingLeft: 12,
              // paddingRight: 12,
            },
          },
        },
        MuiListItemButton: {
          variants: [
            {
              props: { variant: "reportList" },
              style: {
                justifyContent: "center",
                "&.active > *": {
                  color: "white",
                  marginRight: "12px",
                },
                "&:hover": {
                  backgroundColor: "rgba(134,161,179,0.16)",
                },
              },
            },
          ],
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              color: "sideSecondary",
              borderRadius: 5,
              margin: 0,
            },
          },
        },
        MuiFormControl: {
          styleOverrides: {
            root: {
              borderColor: "secondary",
            },
          },
        },
        MuiTableContainer: {
          styleOverrides: {
            root: {
              borderWidth: 1,
            },
          },
        },
        MuiTableHead: {
          root: {
            borderWidth: 1,
            borderRadius: 4,
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              backgroundColor: colors[paletteMode]["primary"],
            },
            arrow: {
              "&::before": {
                backgroundColor: colors[paletteMode]["primary"],
              },
            },
          },
        },
        MuiCssBaseline: {
          styleOverrides: [
            `
          /* work-sans-regular - latin */
          @font-face {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 400;
            src: local('Work Sans Bold Italic'), url(${WorkSansRegularWoff2}) format('woff2');
          }
          /* work-sans-700 - latin */
          @font-face {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 700;
            src: local('Work Sans Bold Italic'), url(${WorkSansBoldWoff2}) format('woff2');
          }
          /* work-sans-italic - latin */
          @font-face {
            font-family: 'Work Sans';
            font-style: italic;
            font-weight: 400;
            src: local('Work Sans Bold Italic'), url(${WorkSansRegularItWoff2}) format('woff2');
          }
          /* work-sans-700italic - latin */
          @font-face {
            font-family: 'Work Sans';
            font-style: italic;
            font-weight: 700;
            src: local('Work Sans Bold Italic'), url(${WorkSansBoldItWoff2}) format('woff2');
          }
          /* work-sans-500 - latin */
          @font-face {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 500;
            src: local('Work Sans Semibold'), url(${WorkSansSemiboldWoff2}) format('woff2');
          }
          /* work-sans-500italic - latin */
          @font-face {
            font-family: 'Work Sans';
            font-style: italic;
            font-weight: 500;
            src: local('Work Sans Semibold Italic'), url(${WorkSansSemiboldItWoff2}) format('woff2');
          }
          div.DraftEditor-root {
            height: 200px;
            width: 100%;
            overflow-y: auto;
          }
          a {
            color: ${colors[paletteMode]["primary"]};
            text-decoration: none;
            transition: text-decoration 0.2s ease-in-out;
          }
          a:hover {
            text-decoration: underline;
          }
          `,
          ],
        },
      },
    };
  };

  // Colors
  const colors = {
    light: {
      primary: "rgb(14, 67, 103)",
      secondary: "rgb(86, 123, 148)",
      border: "rgb(207, 217, 225)",
      sidePrimary: "rgb(50, 169, 161)",
      sideSecondary: "rgb(134, 161, 179)",
      sidebar: "rgb(7, 30, 46)",
      background: "rgb(255, 255, 255)",
      content: "rgb(255, 255, 255)",
    },
    dark: {
      primary: "rgb(37,155,147)",
      secondary: "rgb(40, 135, 129)",
    },
  };

  // Letters
  const healthSystemSignature = "Henry Ford Health System";

  const ReportViewBox = styled(Paper)(({ theme }) => ({
    // color: theme.palette.primary,
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
  }));

  const ReportsGrid = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    paddingLeft: 16,
    paddingTop: 16,
    paddingRight: 24,
    paddingBottom: 16,
    borderRadius: theme.shape.borderRadius,
    maxWidth: "1000px",
  }));

  const IconDisplay = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }));

  const Row = styled(Stack)(({ theme }) => ({
    direction: "row",
    display: "flex",
    alignItems: "center",
    width: "100%",
    paddingX: "4px",
  }));

  const ToolbarDivider = styled(Divider)(({ theme }) => ({
    marginRight: "8px",
    marginLeft: "-8px",
  }));

  useEffect(() => {
    if (showDevOptionsPreference === true) {
      localStorage.setItem("devTools", "true");
    } else if (showDevOptionsPreference === false) {
      localStorage.setItem("devTools", "false");
    }
  }, [showDevOptionsPreference]);

  // Modify the MUI theme according to user configurations
  useEffect(() => {
    if (typeof isDarkMode == "boolean") {
      const paletteMode = isDarkMode ? "dark" : "light";
      setMuiTheme(createTheme(getDesignTokens(paletteMode)));
    }
  }, [isDarkMode]);

  // Change and persist dark mode preferences
  useEffect(() => {
    if (isDarkMode === true) {
      localStorage.setItem("darkMode", "true");
    } else if (isDarkMode === false) {
      localStorage.setItem("darkMode", "false");
    }
  }, [isDarkMode]);

  return (
    <ConfigContext.Provider
      value={{
        openDevTools,
        setOpenDevTools,
        muiTheme,
        ReportViewBox,
        healthSystemSignature,
        IconDisplay,
        ReportsGrid,
        Row,
        ToolbarDivider,
        isDarkMode,
        setIsDarkMode,
        showDevOptions,
        showDevOptionsPreference,
        setShowDevOptionsPreference,
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
