import {
  Box,
  Button,
  Card,
  CircularProgress,
  Collapse,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Logo from "../icons/Logo";
import AgamonLogoText from "../icons/AgamonLogoText";
import Alert from "@mui/material/Alert";
import { LoadingButton } from "@mui/lab";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import axios from "axios";
import { handleSubmitWrapper, handleUIErrors, validateChar } from "../../utils/sso/forms";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ExpiredErrorMessage } from "./messages";
import { logError } from "../../utils/logError";

const Recovery = (props) => {
  const [errorMessage, setErrorMessage] = useState();
  const [infoMessage, setInfoMessage] = useState();
  const [csrfToken, setCsrfToken] = useState();
  const [email, setEmail] = useState();
  const [flowId, setFlowId] = useState();
  const [swiper, setSwiper] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [initializedSettingsFlowId, setInitializedSettingsFlowId] = useState();
  const [isEmailLoading, setIsEmailLoading] = useState(true);
  const [isCodeLoading, setIsCodeLoading] = useState(true);
  const [isNewPasswordLoading, setIsNewPasswordLoading] = useState(true);

  const { control: controlEmail, handleSubmit: handleSubmitEmail } = useForm();
  const { control: controlCode, handleSubmit: handleSubmitCode } = useForm();
  const { control: controlNewPassword, handleSubmit: handleSubmitNewPassword } = useForm();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const submitEmail = async (data) => {
    console.log("Submitting email...", data);
    setEmail(data.email);
    try {
      const response = await axios.post(
        AUTH_API + `/recovery/send-email`,
        { ...data, csrfToken },
        {
          params: { flow: flowId },
          withCredentials: true,
          validateStatus: (status) => [200, 400].includes(status),
        }
      );
      if (response.status === 200) {
        extractDataFromFlow(response.data);
        setIsCodeLoading(false);
        swiper.slideTo(1);
      } else if (response.status === 400) {
        extractDataFromFlow(response.data);
        handleUIErrors(response.data.ui, setErrorMessage);
      }
    } catch (error) {
      if (error.response?.status === 410 && error.response.data?.error?.id === "self_service_flow_expired") {
        setErrorMessage(<ExpiredErrorMessage />);
        console.warn("Session expired:", error.response.data.error.reason);
        return;
      }
      setErrorMessage("Unexpected error occured.");
      logError(error, { componentStack: "Error in submitEmail" });
      console.error("Error submitting email:", error);
    }
  };

  const submitCode = async (data) => {
    console.log("Submitting code...", data);
    try {
      const response = await axios.post(
        AUTH_API + `/recovery/send-code`,
        { ...data, email, csrfToken },
        {
          params: { flow: flowId },
          withCredentials: true,
          validateStatus: (status) => [200, 400, 422].includes(status),
        }
      );
      if ([200, 400].includes(response.status)) {
        extractDataFromFlow(response.data);
        handleUIErrors(response.data.ui, setErrorMessage);
      } else if (response.status === 422 && response.data?.error?.id === "browser_location_change_required") {
        // Successfully completing the recovery flow in AJAX results in this error response, that asks to redirect
        // the user to the settings page.
        const redirectUrl = response.data.redirect_browser_to;
        const url = new URL(redirectUrl);
        const flow = url.searchParams.get("flow");
        setInitializedSettingsFlowId(flow);
        swiper.slideTo(2);
      }
    } catch (error) {
      if (error.response?.status === 410 && error.response.data?.error?.id === "self_service_flow_expired") {
        setErrorMessage(<ExpiredErrorMessage />);
        console.warn("Session expired:", error.response.data.error.reason);
        return;
      }
      setErrorMessage("Unexpected error occured.");
      logError(error, { componentStack: "Error in submitCode" });
      console.error("Error submitting OTP:", error);
    }
  };

  const submitNewPassword = async (data) => {
    console.log("Submitting new password...", data);
    try {
      const response = await axios.post(
        AUTH_API + `/recovery/send-new-password`,
        { ...data, csrfToken },
        {
          params: { flow: flowId },
          withCredentials: true,
          validateStatus: (status) => [200, 400].includes(status),
        }
      );
      if (response.status === 200) {
        console.log("Successfully set new password.");
        extractDataFromFlow(response.data);
        swiper.slideTo(3);
      } else if (response.status === 400) {
        extractDataFromFlow(response.data);
        handleUIErrors(response.data.ui, setErrorMessage);
      }
    } catch (error) {
      if (error.response?.status === 410 && error.response.data?.error?.id === "self_service_flow_expired") {
        setErrorMessage(<ExpiredErrorMessage />);
        console.warn("Session expired:", error.response.data.error.reason);
        return;
      }
      setErrorMessage("Unexpected error occured.");
      logError(error, { componentStack: "Error in submitNewPassword" });
      console.error("Error submitting new password:", error);
    }
  };

  const initializeRecoveryFlow = async () => {
    try {
      const response = await axios.get(AUTH_API + `/recovery/browser`, {
        withCredentials: true,
        validateStatus: (status) => [200, 400].includes(status),
      });
      if (response.status === 200) {
        setIsEmailLoading(false);
        extractDataFromFlow(response.data);
      } else if (response.status === 400) {
        extractDataFromFlow(response.data);
        handleUIErrors(response.data.ui, setErrorMessage);
      }
    } catch (error) {
      setErrorMessage("Unexpected error occured.");
      logError(error, { componentStack: "Error in initializeRecoveryFlow" });
      console.error("Error initializing login flow:", error);
    }
  };

  const getNewRecoveryFlow = async (flowId) => {
    setIsEmailLoading(true);
    swiper.slideTo(0);
    try {
      const response = await axios.get(AUTH_API + `/recovery/flows`, {
        params: {
          flow: flowId,
        },
        withCredentials: true,
        validateStatus: (status) => [200, 400].includes(status),
      });
      if (response.status === 200) {
        setIsEmailLoading(false);
        extractDataFromFlow(response.data);
      } else if (response.status === 400) {
        extractDataFromFlow(response.data);
        handleUIErrors(response.data.ui, setErrorMessage);
      }
    } catch (error) {
      setErrorMessage("Unexpected error occured.");
      logError(error, { componentStack: "Error in initializeRecoveryFlow" });
      console.error("Error initializing login flow:", error);
    }
  };

  const getSettingsFlow = async () => {
    try {
      const response = await axios.get(AUTH_API + `/recovery/send-new-password`, {
        params: {
          flow: initializedSettingsFlowId,
        },
        withCredentials: true,
        validateStatus: (status) => [200, 400].includes(status),
      });
      if (response.status === 200) {
        console.log("Successfully initialized settings flow for setting new password.");
        setIsNewPasswordLoading(false);
        extractDataFromFlow(response.data);
      } else if (response.status === 400) {
        extractDataFromFlow(response.data);
        handleUIErrors(response.data.ui, setErrorMessage);
      }
    } catch (error) {
      setErrorMessage("Unexpected error occured.");
      logError(error, { componentStack: "Error in getSettingsFlow" });
      console.error("Error initializing settings flow:", error);
    }
  };

  const extractDataFromFlow = (flow) => {
    setFlowId(flow.id);
    const csrf = _.find(flow.ui.nodes, { attributes: { name: "csrf_token" } });
    setCsrfToken(csrf?.attributes?.value || "");
    const infoMessage = _.find(flow.ui.messages, { type: "info" });
    if (infoMessage) {
      setInfoMessage(infoMessage.text);
    } else {
      setInfoMessage(null);
    }
  };

  async function logout() {
    try {
      let response = await axios.get(AUTH_API + "/logout/browser", {
        withCredentials: true,
      });
      if (response.status === 200) {
        const logoutToken = response.data.logout_token;
        response = await axios.get(AUTH_API + "/logout", {
          params: {
            token: logoutToken,
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });
        if (response.status === 204) {
          console.log("Logged out.");
        } else {
          console.error(`Unknown response status from /logout - ${response.status}`);
        }
      } else {
        console.error(`Unknown response status - ${response.status}`);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          window.location.reload();
        }
      } else {
        console.error(`Unexpected error while logging out - ${error}`);
      }
    }
  }

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data, status } = await axios.get(AUTH_API + "/whoami", {
          withCredentials: true,
          validateStatus: (status) => [200, 401, 403].includes(status), // Allow 403 to be treated as a valid response
        });
        if (status === 200 && data.active) {
          console.log("User logged in successfully. Redirecting...");
          swiper.slideTo(3);
          return;
        } else if (status === 403 && data.error?.id === "session_aal2_required") {
          console.warn("AAL2 required. Starting flow from beginning by logging the user out.");
          await logout();
        }
        initializeRecoveryFlow();
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (swiper) {
      fetchUser();
    }
  }, [swiper]);

  useEffect(() => {
    if (initializedSettingsFlowId) {
      getSettingsFlow();
    }
  }, [initializedSettingsFlowId]);

  useEffect(() => {
    if (swiper) {
      swiper.updateAutoHeight(300); // Smooth height update with 300ms transition
    }
  }, [isNewPasswordLoading, isEmailLoading, isCodeLoading, swiper]);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card variant="outlined" sx={{ p: 4, width: "500px" }}>
        <Stack spacing={2}>
          <Stack sx={{ pt: 5, alignItems: "center" }} spacing={3}>
            <Stack sx={{ alignItems: "center" }} spacing={1}>
              <Logo
                sx={{
                  fontSize: "2rem",
                  color: "primary.main",
                }}
              />
              <AgamonLogoText sx={{ width: "5rem", color: "primary.main" }} />
            </Stack>
          </Stack>
          <Collapse in={!!errorMessage} sx={{ width: "100%" }} unmountOnExit>
            <Alert sx={{ width: "100%" }} severity="error" children={errorMessage} />
          </Collapse>
          <Collapse in={!!infoMessage} sx={{ width: "100%" }} unmountOnExit>
            <Alert sx={{ width: "100%" }} severity="info" children={infoMessage} />
          </Collapse>
          <Swiper
            autoHeight
            onSlideChange={(swiperInstance) => {
              setErrorMessage(null);
              swiperInstance.updateAutoHeight(swiperInstance.speed);

              if (swiperInstance.activeIndex === 3) {
                console.log(`Redirecting to "/coordinate/manage"`);
                setTimeout(() => {
                  window.location.href = "/coordinate/manage";
                }, 3000);
              }
            }}
            onSwiper={(swiperInstance) => {
              console.log("Setting up swiper instance.");
              setSwiper(swiperInstance);
            }}
            simulateTouch={false}
            touchRatio={0}
            slidesPerView={1}
            enabled={true}
            speed={300} // That's actually the default
          >
            <SwiperSlide>
              {isEmailLoading ? (
                <Stack alignItems={"center"}>
                  <CircularProgress />
                </Stack>
              ) : (
                <form
                  onSubmit={handleSubmitWrapper(handleSubmitEmail, submitEmail, null, setErrorMessage, setInfoMessage)}
                >
                  <Box>
                    <Stack>
                      <Box>
                        <Typography variant={"h5"} sx={{ fontWeight: "bold", mb: 2, mt: 3 }}>
                          Forgot your password?
                        </Typography>
                        <Typography>
                          Please enter the email address associated with your Agamon account, and we'll email you a code
                          to reset your password.
                        </Typography>
                      </Box>
                      <Stack sx={{ width: "100%", my: 3 }}>
                        <InputLabel shrink>Email</InputLabel>
                        <Controller
                          name="email"
                          control={controlEmail}
                          rules={{ required: true }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              disabled={swiper.activeIndex !== 0}
                              placeholder="Email"
                              type={"email"}
                              variant="outlined"
                              inputMode={"email"}
                              aria-label="Email"
                              sx={{ marginBottom: 2 }}
                              fullWidth
                              error={!!fieldState.error}
                            />
                          )}
                        />
                      </Stack>
                      <Stack sx={{ alignItems: "center" }} spacing={1}>
                        <Box sx={{ width: "50%" }}>
                          <Stack spacing={1}>
                            <LoadingButton type={"submit"} variant="contained" color="primary">
                              Continue
                            </LoadingButton>
                            <Button component={Link} to={"/login"}>
                              Back
                            </Button>
                          </Stack>
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>
                </form>
              )}
            </SwiperSlide>

            <SwiperSlide>
              {isCodeLoading ? (
                <Stack alignItems={"center"}>
                  <CircularProgress />
                </Stack>
              ) : (
                <form
                  onSubmit={handleSubmitWrapper(handleSubmitCode, submitCode, null, setErrorMessage, setInfoMessage)}
                >
                  <Box>
                    <Stack spacing={2} sx={{ mt: 2 }}>
                      <Typography>Please enter the code we sent to your email address.</Typography>
                      <Stack sx={{ width: "100%", my: 3 }}>
                        <Controller
                          name="code"
                          control={controlCode}
                          rules={{ required: true }}
                          render={({ field, fieldState }) => (
                            <MuiOtpInput
                              {...field}
                              disabled={swiper.activeIndex !== 1}
                              validateChar={validateChar}
                              length={6}
                              variant="outlined"
                              aria-label="OTP"
                              sx={{ marginBottom: 2 }}
                              fullWidth
                              error={!!fieldState.error}
                              TextFieldsProps={{
                                InputProps: {
                                  "data-1p-ignore": true,
                                },
                              }}
                            />
                          )}
                        />
                      </Stack>
                      <Stack sx={{ alignItems: "center" }} spacing={1}>
                        <Box sx={{ width: "50%" }}>
                          <Stack spacing={1}>
                            <LoadingButton type={"submit"} variant="contained" color="primary">
                              Continue
                            </LoadingButton>
                            <LoadingButton
                              onClick={handleSubmitWrapper(
                                handleSubmitEmail,
                                submitEmail,
                                null,
                                setErrorMessage,
                                setInfoMessage
                              )}
                              variant="outlined"
                              color="primary"
                            >
                              Resend code
                            </LoadingButton>
                          </Stack>
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>
                </form>
              )}
            </SwiperSlide>

            <SwiperSlide>
              {isNewPasswordLoading ? (
                <Stack alignItems={"center"}>
                  <CircularProgress />
                </Stack>
              ) : (
                <form
                  onSubmit={handleSubmitWrapper(
                    handleSubmitNewPassword,
                    submitNewPassword,
                    null,
                    setErrorMessage,
                    setInfoMessage
                  )}
                >
                  <Box>
                    <Stack spacing={2} sx={{ mt: 2 }}>
                      <Typography>Set up new password</Typography>
                      <Stack sx={{ width: "100%", my: 3 }}>
                        <Controller
                          name="password"
                          control={controlNewPassword}
                          rules={{ required: true }}
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              disabled={swiper.activeIndex !== 2}
                              placeholder={"New password"}
                              variant="outlined"
                              error={!!fieldState.error}
                              type={showPassword ? "text" : "password"}
                              InputProps={{
                                "data-1p-ignore": true,
                                autocomplete: "new-password",
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Stack>
                      <Stack sx={{ alignItems: "center" }} spacing={1}>
                        <Box sx={{ width: "50%" }}>
                          <Stack spacing={1}>
                            <LoadingButton type={"submit"} variant="contained" color="primary">
                              Continue
                            </LoadingButton>
                          </Stack>
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>
                </form>
              )}
            </SwiperSlide>

            <SwiperSlide>
              <Box sx={{ textAlign: "center" }}>
                <Stack spacing={3} alignItems="center">
                  <CircularProgress thickness={5} size={30} sx={{ mt: 2 }} />
                </Stack>
              </Box>
            </SwiperSlide>
          </Swiper>
        </Stack>
      </Card>
    </Box>
  );
};

export default Recovery;
