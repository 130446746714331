import React from "react";
import { createRoot } from "react-dom/client";
import AppWrapper from "./AppWrapper";
import AppContent from "./AppContent";

const App = (props) => {
  return (
    <AppWrapper>
      <AppContent />
    </AppWrapper>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
