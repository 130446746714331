import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Invite from "./Invite";
import _ from "lodash";
import { handleSubmitWrapper, handleUIErrors } from "../../utils/sso/forms";
import { useForm } from "react-hook-form";

const InviteWrapper = () => {
  const { id: inviteId } = useParams();
  const [inviteData, setInviteData] = useState(null);
  const [flowId, setFlowId] = useState(null);
  const [csrfToken, setCsrfToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [loadingStep, setLoadingStep] = useState(false);
  const [formData, setFormData] = useState({});
  const [isValid, setIsValid] = useState();
  const [totpQr, setTotpQr] = useState();
  const [totpUnlink, setTotpUnlink] = useState();
  const [totpSecret, setTotpSecret] = useState();
  const [activeStep, setActiveStep] = React.useState(0);
  const { control, handleSubmit } = useForm();

  const extractCsrfToken = (uiNodes) => {
    const csrfNode = _.find(uiNodes, (node) => _.get(node, "attributes.name") === "csrf_token");
    const token = _.get(csrfNode, "attributes.value", null);
    console.log("CSRF token extracted:", token ? "Yes" : "No");
    return token;
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(AUTH_API + `/whoami`, {
          withCredentials: true,
          validateStatus: (status) => status === 200 || status === 401,
        });
        if (response.status === 200) {
          const { active, authenticator_assurance_level } = response.data;

          if (active && authenticator_assurance_level === "aal1") {
            console.log("User is active with aal1. Skipping to MFA setup.");
            setActiveStep(2); // Registration not required, proceed to MFA setup
            await createSettingsFlow();
          } else if (active && authenticator_assurance_level === "aal2") {
            console.log("User is active with aal2. Skipping registration.");
            setActiveStep(3); // User already fully registered
          }
        } else if (response.status === 401) {
          console.log("No valid session credentials found. Proceeding with invite flow.");
        }
        fetchInviteData();
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUser();
  }, []);

  const fetchInviteData = async () => {
    try {
      console.log("Fetching invite data for inviteId:", inviteId);
      const response = await axios.get(AUTH_API + `/invites/validate`, {
        params: { inviteId },
        validateStatus: (status) => status === 200 || status === 404,
        withCredentials: true,
      });

      if (response.status === 200) {
        setInviteData(response.data.inviteData);
        console.log("Invite data fetched.");
        fetchRegistrationFlow();
      }
      console.log(`Changing validity to ${response.data.valid}`);
      setIsValid(response.data.valid);
      if (response.data.valid === false) {
        setInfoMessage("This link is either invalid or expired.");
      }
    } catch (error) {
      console.error("Error loading invite:", error);
      setErrorMessage("Error loading invite.");
    }
  };

  const fetchRegistrationFlow = async () => {
    try {
      console.log("Fetching registration flow for inviteId:", inviteId);
      const response = await axios.get(AUTH_API + "/invites/register/browser", {
        params: { inviteId },
        headers: {
          Accept: "application/json",
        },
        withCredentials: true,
        validateStatus: (status) => status === 200 || status === 400,
      });

      if (response.status === 400) {
        console.log("Handling registration flow errors...");
        handleUIErrors(response.data.ui, setErrorMessage);
      } else if (response.status === 200) {
        setFlowId(response.data.id);
        setCsrfToken(extractCsrfToken(response.data.ui.nodes));
        console.log("Registration flow fetched with flowId:", response.data.id);
      }
    } catch (error) {
      console.error("Error fetching registration flow:", error);
      setErrorMessage("Error fetching registration flow.");
    }
  };

  const submitPersonalDetails = async (data) => {
    try {
      console.log("Validating personal details:", data);
      setFormData((prev) => ({ ...prev, ...data }));
      setActiveStep(1);
      return Promise.resolve(); // Simulate successful validation
    } catch (error) {
      console.error("Error validating personal details:", error);
      throw new Error("Error validating personal details.");
    }
  };

  const submitPassword = async (data) => {
    const { password, confirmPassword } = data;

    if (password !== confirmPassword) {
      console.error("Password validation failed: passwords do not match.");
      throw new Error("Passwords do not match. Please try again.");
    }

    try {
      console.log("Submitting registration data with password:", { ...formData, password });
      const response = await axios.post(
        AUTH_API + `/invites/register`,
        {
          inviteId,
          ...formData,
          password,
          csrfToken,
        },
        {
          params: {
            inviteId,
            flow: flowId,
          },
          headers: {
            Accept: "application/json",
          },
          withCredentials: true,
          validateStatus: (status) => status === 200 || status === 400,
        }
      );
      if (response.status === 400) {
        console.log("Handling registration flow errors...");
        handleUIErrors(response.data.ui, setErrorMessage);
      } else if (response.status === 200) {
        if (response.data.session.active === true) {
          console.log("Registration successful, initializing MFA settings...");
          await createSettingsFlow();
        }
      }
    } catch (error) {
      console.error("Error during registration:", error);
      throw new Error("Error during registration.");
    }
  };

  const createSettingsFlow = async () => {
    try {
      setActiveStep(2);
      console.log("Initializing settings flow for MFA...");
      const response = await axios.get(AUTH_API + `/invites/register/mfa`, {
        params: { inviteId },
        withCredentials: true,
      });
      setFlowId(response.data.id);
      setCsrfToken(extractCsrfToken(response.data.ui.nodes));
      setTotpQr(
        _.find(response.data.ui.nodes, {
          attributes: { id: "totp_qr" },
        })
      );
      setTotpUnlink(
        _.find(response.data.ui.nodes, {
          attributes: { name: "totp_unlink" },
        })
      );
      setTotpSecret(
        _.find(response.data.ui.nodes, {
          attributes: { text: { id: 1050006 } },
        })
      );
      console.log("Settings flow initialized with flowId:", response.data.id);
    } catch (error) {
      console.error("Error initializing settings flow:", error);
      throw new Error("Error initializing settings flow.");
    }
  };

  const submitMFA = async (data) => {
    try {
      console.log("Submitting MFA setup:", data);
      setErrorMessage(null);

      const response = await axios.post(
        AUTH_API + `/invites/register/mfa`,
        {
          totpCode: data.mfaCode,
          csrfToken,
        },
        {
          params: {
            inviteId,
            flow: flowId,
          },
          withCredentials: true,
          validateStatus: (status) => status === 200 || status === 400, // Do not throw for 400
        }
      );
      if (response.status === 200 && response.data.identity && response.data.state === "success") {
        setActiveStep(3);
      }
      if (response.status === 400 && response.data?.id) {
        // Handle form errors
        handleUIErrors(response.data.ui.nodes, setErrorMessage);
      } else {
        setTimeout(() => {
          window.location.href = "/";
        }, 3000);
        console.log("MFA setup complete. Redirecting...");
      }
    } catch (error) {
      console.error("Unexpected error setting up MFA:", error);
      setErrorMessage("An unexpected error occurred during MFA setup.");
    }
  };

  return (
    <Invite
      inviteData={inviteData}
      isValid={isValid}
      errorMessage={errorMessage}
      infoMessage={infoMessage}
      loadingStep={loadingStep}
      submitPersonalDetails={handleSubmitWrapper(
        handleSubmit,
        submitPersonalDetails,
        setLoadingStep,
        setErrorMessage,
        setInfoMessage
      )}
      submitPassword={handleSubmitWrapper(
        handleSubmit,
        submitPassword,
        setLoadingStep,
        setErrorMessage,
        setInfoMessage
      )}
      submitMFA={handleSubmitWrapper(handleSubmit, submitMFA, setLoadingStep, setErrorMessage, setInfoMessage)}
      totpQr={totpQr}
      totpSecret={totpSecret}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      control={control}
    />
  );
};

export default InviteWrapper;
