import React, { useEffect, useState, useRef, forwardRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useTracking } from "react-tracking";

import PhysicianLink from "./PhysicianLink";
import { useForm } from "react-hook-form";
import { FormatMessages } from "./FormatMessages";
import { logError } from "../../utils/logError";
import useConfig from "../../providers/useConfig";
import Turnstile from "react-turnstile";
import { ExpiredErrorMessage } from "./messages";

const PhysicianLinkWrapper = () => {
  const { id } = useParams();
  const [linkData, setLinkData] = useState(null);
  const [loginFlow, setLoginFlow] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [otpFieldError, setOtpFieldError] = useState(null);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [otp, setOtp] = useState("");
  const [disabledDueToError, setDisabledDueToError] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [wasResendClicked, setWasResendClicked] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [token, setToken] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [captchaSiteKey, setCaptchaSiteKey] = useState();

  const tracking = useTracking({ page: "Physician link sign-in" });
  const { handleSubmit: handleSubmitSendEmailWithOtp } = useForm();
  const { handleSubmit: handleSubmitOtp } = useForm();

  useEffect(() => {
    const fetchCaptchaSiteKey = async () => {
      console.debug("Fetching CAPTCHA site key...");
      try {
        const response = await axios.get(AUTH_API + "/captcha-sitekey");
        const siteKey = response.data?.captchaSiteKey;
        if (siteKey) {
          console.debug("Fetched site key.");
          setCaptchaSiteKey(siteKey);
        }
      } catch (error) {
        setErrorMessage("Error loading key.");
        logError(error, {
          componentStack: "Error in fetchCaptchaSiteKey",
          message: "Error fetching CAPTCHA site key.",
        });
        tracking.trackEvent({ message: `Error fetching link data.`, error, linkId: id });
      }
    };
    fetchCaptchaSiteKey();
  }, []);

  useEffect(() => {
    const fetchAuthData = async () => {
      try {
        const response = await axios.get(AUTH_API + `/physicians/links/validate-link-and-register/${id}`);
        setLinkData(response.data.linkData);
      } catch (error) {
        setErrorMessage("Error loading link.");
        logError(error, { componentStack: "Error in PhysicianLinkWrapper", message: "Error fetching link data." });
        tracking.trackEvent({ message: `Error fetching link data.`, error, linkId: id });
      }
    };
    fetchAuthData();
  }, []);

  useEffect(() => {
    if (linkData && !loginFlow) {
      tracking.trackEvent({ message: "Link data has loaded, creating flow...", linkId: id });
      if (linkData.isExpired === false) {
        const createLoginFlow = async () => {
          try {
            const response = await axios.get(AUTH_API + "/physicians/links/login/create-otp-flow", {
              params: {
                linkId: linkData.linkId,
              },
            });
            setLoginFlow(response.data);
          } catch (error) {
            handleErrorResponse(error);
          }
        };
        createLoginFlow();
      }
    }
  }, [linkData, loginFlow]);

  useEffect(() => {
    if (swiperInstance && activeSlide) {
      swiperInstance.slideTo(activeSlide);
    }
  }, [swiperInstance, activeSlide]);

  useEffect(() => {
    if (swiperInstance && loginFlow) {
      const infoMessages = _.filter(loginFlow.ui?.messages, { type: "info" });
      const sentEmailMessage = _.find(infoMessages, { id: 1010014 });
      if (sentEmailMessage) {
        if (swiperInstance.activeIndex === 0) {
          setActiveSlide(1);
        } else if (swiperInstance.activeIndex === 1 && wasResendClicked) {
          setInfoMessage(<FormatMessages messages={[sentEmailMessage]} />);
        }
      }
      if (swiperInstance.activeIndex === 2) {
        tracking.trackEvent({
          message: "User entered OTP, got to the 'You're in' view and is being redirected to content.",
          loginFlowId: loginFlow.id,
          linkId: id,
        });
        redirectToReturnTo();
      }
    }
  }, [swiperInstance, loginFlow, activeSlide]);

  async function handleSuccessfulResponse(result) {
    setLoginFlow(result);
    const errorMessages = _.filter(result.ui?.messages, { type: "error" });
    const otpField = _.find(result.ui.nodes, { group: "code", type: "input" });
    const otpFieldMessages = otpField.messages;
    const otpErrors = _.filter(otpFieldMessages, { type: "error" });
    if (otpErrors.length > 0) {
      setOtpFieldError(otpErrors);
    }
    if (errorMessages.length > 0) {
      tracking.trackEvent({
        message: "User faced the following error messages, showing in the Alert",
        errorMessages,
        loginFlowId: loginFlow.id,
        linkId: id,
      });
      setErrorMessage(<FormatMessages messages={errorMessages} />);
    }
  }

  function handleErrorResponse(error) {
    if (error.response?.data) {
      const body = error.response.data;
      if (body.error) {
        const error = body.error;
        if (error.id === "session_already_available") {
          redirectToReturnTo();
          return;
        } else if (error.id === "self_service_flow_expired") {
          setErrorMessage(<ExpiredErrorMessage />);
          return;
        }
        tracking.trackEvent({
          message: "User faced the following error messages, showing in the Alert",
          error,
          ...(loginFlow && { loginFlowId: loginFlow.id }),
          linkId: id,
        });
        setErrorMessage(JSON.stringify(error));
        return;
      }
    } else {
      console.error("An unexpected error occurred:", error);
      logError(error, {
        componentStack: "Error in handleErrorResponse",
        message: "User faced with 'An unknown error occurred.'",
      });
      tracking.trackEvent({
        error: "User faced with 'An unknown error occurred.'",
        response: error.response,
        ...(loginFlow && { loginFlowId: loginFlow.id }),
        linkId: id,
      });
      setErrorMessage("An unknown error occurred.");
    }
  }

  async function handleSuccessfulEmailWithOtp(result) {
    await handleSuccessfulResponse(result);
  }

  async function handleSuccessfulEmailWithNewOtp(result) {
    setTimeout(() => {
      // Your code here
      setShowCaptcha(false);
    }, 2000); // 2000 milliseconds = 2 seconds
    await handleSuccessfulResponse(result);
  }

  async function handleSuccessfulOtpSubmission(result) {
    if (result.active === "code") {
      await handleSuccessfulResponse(result);
    } else {
      setActiveSlide(2);
    }
  }

  async function handleResendCodeButtonClick() {
    if (!showCaptcha) {
      setShowCaptcha(true);
    }
  }

  async function sendEmailWithOtp() {
    try {
      tracking.trackEvent({ message: "User requested to send OTP.", loginFlowId: loginFlow.id, linkId: id });
      setInfoMessage(null);
      setErrorMessage(null);
      const result = await axios.post(
        AUTH_API + "/physicians/links/login/send-otp-to-email",
        {
          linkId: linkData.linkId,
          flowId: loginFlow.id,
          csrfToken: extractAndSetCsrfTokenFromFlow(loginFlow),
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
        }
      );
      await handleSuccessfulEmailWithOtp(result.data);
    } catch (err) {
      await handleErrorResponse(err);
    }
  }

  async function sendEmailWithNewOtp(captchaToken) {
    try {
      setWasResendClicked(true);
      setInfoMessage(null);
      setErrorMessage(null);
      const result = await axios.post(
        AUTH_API + "/physicians/links/login/resend-otp-to-email",
        {
          linkId: linkData.linkId,
          flowId: loginFlow.id,
          csrfToken: extractAndSetCsrfTokenFromFlow(loginFlow),
          captchaToken,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
        }
      );
      await handleSuccessfulEmailWithNewOtp(result.data);
    } catch (err) {
      await handleErrorResponse(err);
    }
  }

  async function submitOtp() {
    try {
      setInfoMessage(null);
      setErrorMessage(null);
      setOtpFieldError(null);
      const result = await axios.post(
        AUTH_API + "/physicians/links/login/complete-flow",
        {
          linkId: linkData.linkId,
          flowId: loginFlow.id,
          csrfToken: extractAndSetCsrfTokenFromFlow(loginFlow),
          code: otp,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
          },
        }
      );
      await handleSuccessfulOtpSubmission(result.data);
    } catch (err) {
      await handleErrorResponse(err);
    }
  }

  async function turnstileOnVerify(token) {
    setToken(token);
    setIsVerified(true);
    sendEmailWithNewOtp(token);
  }

  function turnstileOnExpire() {
    setIsVerified(false);
    setToken(null);
  }

  function turnstileOnError() {
    setIsVerified(false);
    setToken(null);
  }

  function extractAndSetCsrfTokenFromFlow(flow) {
    return _.find(flow.ui.nodes, {
      attributes: { name: "csrf_token" },
    }).attributes.value;
  }

  function redirectToReturnTo() {
    const url = linkData?.returnTo;
    if (url) {
      window.location.assign(url);
    }
  }

  async function sendEmailWithNewLink() {
    logError(error, {
      componentStack: "Error in PhysicianLinkWrapper",
      message: "User clicked on 'Resend link' when it's not implemented yet.",
    });
  }

  return (
    <PhysicianLink
      linkData={linkData}
      errorMessage={errorMessage}
      infoMessage={infoMessage}
      otp={otp}
      disabledDueToError={disabledDueToError}
      setOtp={setOtp}
      sendOtp={handleSubmitSendEmailWithOtp(sendEmailWithOtp)}
      resendLink={sendEmailWithNewLink}
      resendOtp={sendEmailWithNewOtp}
      submitOtp={handleSubmitOtp(submitOtp)}
      otpFieldError={otpFieldError}
      swiperInstance={swiperInstance}
      setSwiperInstance={setSwiperInstance}
      showCaptcha={showCaptcha}
      TurnstileWidget={
        <Turnstile
          sitekey={captchaSiteKey}
          onVerify={turnstileOnVerify}
          onError={turnstileOnError}
          OnExpire={turnstileOnExpire}
        />
      }
      handleResendCodeButtonClick={handleResendCodeButtonClick}
    />
  );
};

export default PhysicianLinkWrapper;
